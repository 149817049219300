import { ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Grid, Stack, Typography } from '@krakentech/coral';

import {
  AccountEnrichmentCard,
  AuthenticatedPageLayout,
  BalanceCard,
  DashboardGreeting,
  DirectDebitActionCard,
  HomeMoveCard,
  LatestBillCard,
  MakeAOneOffPaymentCard,
  MoveOutCard,
  NextPaymentCard,
  ParentPageLayout,
  PaymentFeaturesComingSoonBanner,
  SubmitNewReadingCard,
  UpcomingPaymentSchedule,
} from '@/components';
import { ANIMATED_ALERT_STATUS } from '@/consts/animatedAlertStatus';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useCurrentActivePaymentSchedule } from '@/hooks/billsAndPayments/useCurrentActivePaymentSchedule';
import { useHasNotMovedOut } from '@/hooks/moveHome/useHasNotMovedOut';
import { useOccupancyPeriods } from '@/hooks/moveHome/useOccupancyPeriods';
import { useWillNotMoveOutInFuture } from '@/hooks/moveHome/useWillNotMoveOutInFuture';
import { useLatestReading } from '@/hooks/usage/useLatestReading';
import { useFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import { closeAnimatedAlert } from '@/utils/alerts';
import { INTERNAL_PATHS } from '@/utils/urls';

export const Dashboard = () => {
  const { query } = useRouter();

  const [numberOfDesktopBalanceColumns, setNumberOfDesktopBalanceColumns] =
    useState(1);

  const { data: currentPaymentSchedule } = useCurrentActivePaymentSchedule();
  const { data: hasNotMovedOut, isFetched: hasNotMovedOutFetched } =
    useHasNotMovedOut();
  const {
    data: willNotMoveOutInTheFuture,
    isFetched: willNotMoveOutInTheFutureFetched,
  } = useWillNotMoveOutInFuture();
  const { data: occupancyPeriodsData } = useOccupancyPeriods();

  const [alertStatus, setAlertStatus] = useState(ANIMATED_ALERT_STATUS.CLOSED);

  useEffect(() => {
    if (query.contactDetailsSuccess) setAlertStatus(ANIMATED_ALERT_STATUS.OPEN);
  }, [query.contactDetailsSuccess]);

  const showAccountEnrichment = useFeatureFlag(FeatureNames.AccountEnrichment);

  const showUsageCard = useFeatureFlag(FeatureNames.Usage);
  const showBillsCard = useFeatureFlag(FeatureNames.Bills);
  const showOnlinePayment = useFeatureFlag(FeatureNames.OnlinePayment);
  const showDirectDebit = useFeatureFlag(FeatureNames.DirectDebit);
  const showHomeMove = useFeatureFlag(FeatureNames.HomeMove);

  const { accountNumber } = useAccountNumberContext();

  const { data: latestReadingData } = useLatestReading({ accountNumber });

  const hasFixedPaymentSchedule =
    currentPaymentSchedule?.node &&
    !currentPaymentSchedule?.node.isVariablePaymentAmount;

  const showHomeMoveCard =
    showHomeMove && hasNotMovedOut && willNotMoveOutInTheFuture;

  const movingOutDate =
    occupancyPeriodsData?.properties[0]?.occupancyPeriods[0]?.effectiveTo;

  const isInMovingProcess =
    (hasNotMovedOutFetched && !hasNotMovedOut) ||
    (willNotMoveOutInTheFutureFetched && !willNotMoveOutInTheFuture);

  const showDirectDebitCard =
    showDirectDebit && hasNotMovedOut && willNotMoveOutInTheFuture;

  const hasActiveMeter =
    !!latestReadingData?.properties[0]?.activeWaterMeters.length;

  const isInMovingProcessWithoutActiveMeter =
    !hasActiveMeter && isInMovingProcess;

  const showSubmitNewReadingCard =
    showUsageCard && !isInMovingProcessWithoutActiveMeter;

  const showAccountEnrichmentCard =
    showAccountEnrichment && hasNotMovedOutFetched && hasNotMovedOut;

  return (
    <>
      <ParentPageLayout
        pageHeading={<DashboardGreeting />}
        alertMessage="Your personal details have been updated"
        alertStatus={alertStatus}
        handleAlertClose={() => closeAnimatedAlert(setAlertStatus)}
      >
        <Stack
          direction="vertical"
          gap="md"
          md={{ gap: 'lg', direction: 'vertical' }}
        >
          {isInMovingProcess && (
            <MoveOutCard {...(movingOutDate && { movingOutDate })} />
          )}

          <PaymentFeaturesComingSoonBanner />

          {showDirectDebit && <UpcomingPaymentSchedule />}

          {showAccountEnrichmentCard && <AccountEnrichmentCard />}

          <Grid
            gap="smMd"
            templateColumns={`repeat(1, 1fr)`}
            md={{
              gap: 'md',
              templateColumns: 'repeat(1, 1fr)',
            }}
            lg={{
              gap: 'md',
              templateColumns: `repeat(${numberOfDesktopBalanceColumns}, 1fr)`,
            }}
          >
            <BalanceCard />
            {showDirectDebit && hasFixedPaymentSchedule && (
              <NextPaymentCard
                setNumberOfDesktopColumns={setNumberOfDesktopBalanceColumns}
              />
            )}
          </Grid>

          <Stack gap="sm" md={{ gap: 'md' }} direction="vertical">
            <Typography variant="h2">Quick links</Typography>

            <Grid
              templateColumns={'repeat(1, 1fr)'}
              gap="sm"
              md={{
                gap: 'md',
                templateColumns: 'repeat(1, 1fr)',
              }}
              lg={{
                templateColumns: 'repeat(2, 1fr)',
              }}
            >
              {showDirectDebitCard && <DirectDebitActionCard />}

              {showOnlinePayment && <MakeAOneOffPaymentCard />}

              {showBillsCard && <LatestBillCard />}

              {showSubmitNewReadingCard && <SubmitNewReadingCard />}

              {showHomeMoveCard && <HomeMoveCard />}
            </Grid>
          </Stack>
        </Stack>
      </ParentPageLayout>
    </>
  );
};

Dashboard.getLayout = (page: ReactNode) => (
  <AuthenticatedPageLayout pageTitle={INTERNAL_PATHS.HOME.title}>
    {page}
  </AuthenticatedPageLayout>
);

export default Dashboard;
